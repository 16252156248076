import React from 'react';
import Link from 'next/link';
import { Box } from '@mui/system';
import Button from '@/shared/components/ButtonComponent';
import Icon from '@/shared/components/Icon';
import { Typography } from '@mui/material';
import { IconsNames } from '@/shared/components/Icon/icons.types';
import { colors } from '@/lib/theme/palette';

type Props = {
  open: boolean;
  isAuth: boolean;
  onOpenLoginModal: () => void;
  onOpenSingUpModal: () => void;
  onClose: () => void;
  onLogout: () => void;
};

type LinkItemType = {
  text: string;
  icon: IconsNames;
  onClick?: () => void;
};

const links: (LinkItemType & { href: string })[] = [
  {
    href: '/library?type=resources&pageCount=12',
    text: 'Library',
    icon: 'library',
  },

  {
    href: '/technologies',
    text: 'Technologies',
    icon: 'categories',
  },

  {
    href: '/',
    text: 'For Business',
    icon: 'business',
  },
];

const BurgerItem = ({ icon, text, onClick }: LinkItemType) => (
  <Box display={'flex'} alignItems={'center'} sx={{ cursor: 'pointer' }}>
    <Button
      width={'36px'}
      height={'36px'}
      variant="outlined-primary"
      sx={{ borderRadius: '4px' }}
      onClick={onClick}
    >
      <Icon icon={icon} width={'16px'} height={'16px'} color={colors.blue} />
    </Button>

    <Typography variant="Tab" fontWeight={'&00'} sx={{ marginLeft: '16px' }}>
      {text}
    </Typography>
  </Box>
);

const BurgerMenu = ({
  open,
  isAuth,
  onClose,
  onOpenLoginModal,
  onOpenSingUpModal,
  onLogout,
}: Props) => {
  const handleOpenModal = () => {
    onOpenLoginModal();
    onClose();
  };

  const handleOpenSingUpModal = () => {
    onOpenSingUpModal();
    onClose();
  };

  const handleLogout = () => {
    onLogout();
    onClose();
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'fixed',
          maxWidth: '320px',
          width: '100%',
          height: 'calc(100% - 76px)',
          right: 0,
          top: '76px',
          backgroundColor: 'white',
          transform: open ? 'translateX(0)' : 'translateX(100%)',
          transition: 'transform 0.2s ease-in-out',
          zIndex: 101,
          padding: '36px 28px',
        }}
      >
        <Box display={'grid'} rowGap={'24px'}>
          {links.map(link => (
            <Link
              href={link.href}
              key={link.href}
              style={{ display: 'block', textDecoration: 'none' }}
              onClick={onClose}
            >
              <BurgerItem icon={link.icon} text={link.text} />
            </Link>
          ))}
        </Box>

        <Box
          marginTop={'auto'}
          display={isAuth ? 'grid' : 'flex'}
          rowGap={'24px'}
          alignItems={'center'}
        >
          {isAuth ? (
            <>
              <Link
                href={'/profile/settings'}
                style={{ display: 'block', textDecoration: 'none' }}
                onClick={onClose}
              >
                <BurgerItem icon={'user'} text={'Profile'} />
              </Link>

              <BurgerItem
                icon={'logout'}
                text={'Log out'}
                onClick={handleLogout}
              />
            </>
          ) : (
            <>
              <Button
                variant="outlined-primary"
                height={'36px'}
                sx={{ marginRight: '16px', borderRadius: '4px' }}
                onClick={handleOpenModal}
              >
                Sign in
              </Button>

              <Button
                height={'36px'}
                variant="filled"
                sx={{ borderRadius: '4px' }}
                onClick={handleOpenSingUpModal}
              >
                Sign up
              </Button>
            </>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 99,
          display: open ? 'block' : 'none',
        }}
        onClick={onClose}
      />
    </>
  );
};

export default BurgerMenu;
