import { useState } from 'react';
import Link from 'next/link';

import { Box, SxProps, useMediaQuery } from '@mui/system';
import { Menu, MenuItem, Tooltip } from '@mui/material';

import { IconsNames } from '@/shared/components/Icon/icons.types';

import Button from '@/shared/components/ButtonComponent';
import Icon from '@/shared/components/Icon';

import theme from '@/lib/theme/theme';
import { colors } from '@/lib/theme/palette';
import { UserType } from '@/lib/store/user.store';
import useQueryParams from '@/shared/hooks/useQueryParams.hook';

const buttonsStyles = {
  height: {
    xs: '36px',
    sm: '48px',
    xl: '60px',
  },
  fontSize: {
    md: '16px',
    xl: '20px',
  },
};

const buttonsHeaderStyles: SxProps = {
  width: {
    xs: '36px',
    xl: '48px',
  },
  height: {
    xs: '36px',
    xl: '48px',
  },
};

const IconHeaderStyles: SxProps = {
  width: {
    xs: '16px',
    xl: '28px',
  },
  height: {
    xs: '16px',
    xl: '28px',
  },
};

type Props = {
  isAuth: boolean;
  user: UserType | null;
  openBurgerMenu: boolean;
  onOpenModal: () => void;
  onToggleMenu: () => void;
  onOpenSearchMenu: () => void;
  onLogout: () => void;
};

type HeaderButtonsProps = {
  text: string;
  icon: IconsNames;
  sx?: SxProps;
  onClick?: () => void;
};

const HeaderButton = (props: HeaderButtonsProps) => {
  const { sx, text, icon, onClick } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const styles = {
    ...buttonsStyles,
    ...sx,
  };

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Button
      component="span"
      variant="outlined-secondary"
      prepend={!isMobile && <Icon icon={icon} width={20} height={20} />}
      sx={styles}
      onClick={handleOnClick}
    >
      {isMobile ? <Icon icon={icon} width={16} height={16} /> : text}
    </Button>
  );
};

const HeaderButtons = ({
  isAuth,
  user,
  openBurgerMenu,
  onOpenModal,
  onToggleMenu,
  onOpenSearchMenu,
  onLogout,
}: Props) => {
  const isMiddleScreen = useMediaQuery(theme.breakpoints.up('md'));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isAuth) {
      setAnchorEl(event.currentTarget);
    }

    if (!isAuth) {
      onOpenModal();
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { setQueries } = useQueryParams({});

  return isMiddleScreen ? (
    <Box
      component="nav"
      display="flex"
      columnGap={1}
      marginLeft={{
        xs: 'auto',
      }}
    >
      <Tooltip title="Library">
        {window.location.pathname === '/library' ? (
          <HeaderButton
            icon="library"
            text="Library"
            sx={{
              width: {
                xs: '36px',
                sm: 'auto',
              },
              paddingX: {
                xs: '10px',
                sm: '14px',
                xl: '16px',
              },
            }}
            onClick={() =>
              setQueries({ type: 'resources', page: '1', pageCount: '12' })
            }
          />
        ) : (
          <Link
            href="/library?page=1&pageCount=12&type=resources"
            style={{ display: 'block', textDecoration: 'none' }}
          >
            <HeaderButton
              icon="library"
              text="Library"
              sx={{
                width: {
                  xs: '36px',
                  sm: 'auto',
                },
                paddingX: {
                  xs: '10px',
                  sm: '14px',
                  xl: '16px',
                },
              }}
            />
          </Link>
        )}
      </Tooltip>
      <Tooltip title="Technologies">
        <Link
          href="/technologies"
          style={{ display: 'block', textDecoration: 'none' }}
        >
          <HeaderButton
            icon="categories"
            text="Technologies"
            sx={{
              width: {
                xs: '36px',
                sm: 'auto',
              },
              paddingX: {
                xs: '10px',
                sm: '14px',
                xl: '16px',
              },
            }}
          />
        </Link>
      </Tooltip>

      <Tooltip title="For Business">
        <Box>
          <HeaderButton
            icon="business"
            text="For Business"
            sx={{
              flex: '1 0 auto',
              width: {
                xs: '36px',
                sm: '146px',
                xl: '174px',
              },
              paddingX: {
                xs: '10px',
                sm: '14px',
                xl: '16px',
              },
            }}
          />
        </Box>
      </Tooltip>

      <Tooltip title={isAuth ? 'Profile' : 'Login'}>
        <Box>
          <Button
            variant="filled"
            sx={{
              ...buttonsStyles,
              flex: '1 0 auto',
              padding: '0px',
              width: {
                xs: '36px',
                sm: '48px',
                xl: '60px',
              },
            }}
            onClick={handleClick}
          >
            {isAuth ? (
              `${user?.first_name[0]} ${user?.last_name[0]}`
            ) : (
              <Icon
                icon="user"
                width={{ xs: 16, sm: 24 }}
                height={{ xs: 16, sm: 24 }}
                color={colors.white}
              />
            )}
          </Button>
        </Box>
      </Tooltip>

      {isAuth && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <Link href="/profile/settings">
            <MenuItem onClick={handleClose}>Profile</MenuItem>
          </Link>
          <MenuItem onClick={onLogout}>Logout</MenuItem>
        </Menu>
      )}
    </Box>
  ) : (
    <Box
      component="nav"
      display="flex"
      alignItems={'center'}
      columnGap={4}
      marginLeft={{
        xs: 'auto',
      }}
    >
      <Button
        type="button"
        aria-label="search"
        variant="outlined-primary"
        sx={{
          borderRadius: '50%',
          border: '1px solid #C9D3FF',
          ...buttonsHeaderStyles,
        }}
        onClick={onOpenSearchMenu}
      >
        <Icon icon="search" color={colors.blue} sx={IconHeaderStyles} />
      </Button>
      <Button
        height={'32px'}
        width={'32px'}
        variant="outlined-secondary"
        sx={{
          border: 'none',
        }}
        onClick={onToggleMenu}
      >
        <Icon
          icon={openBurgerMenu ? 'close' : 'menu'}
          width={'32px'}
          height={'32px'}
        />
      </Button>
    </Box>
  );
};
export default HeaderButtons;
